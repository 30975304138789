import React from 'react';
import { graphql } from 'gatsby';
import GameCreationRoot from '../../components/game-creation-root';
import BeGoldenBallsForm from '../../components/game-forms/be-golden-balls-form';
import BeGameInstructions from '../../components/be-game-instructions';
import useLocalStorageState from 'use-local-storage-state'

const GoldenBallsPage = (props) => {
  const [experiment, setExperiment] = useLocalStorageState("experiment");
  const [dataUser, setDataUser] = React.useState();
  const handleOnChange = (event) => {
    dataUser[event.target.name] = event.target.value;
    setDataUser({...dataUser});
    console.log(dataUser);
  };

  React.useEffect(() => {
    setDataUser(props.location.state?.gamePosition !== undefined && experiment?.games[props.location.state.gamePosition].params || {
        numberOfVideos: 5,
        videoOrder: 'fixed',
      }
    );
  }, []);

	return(
        <GameCreationRoot title={"Golden Balls Game"}>
            <BeGameInstructions gameId={'goldenballs'} dataUser={dataUser}  />
            <BeGoldenBallsForm handleOnChange={handleOnChange} />
        </GameCreationRoot>
	);
}

export default GoldenBallsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "games", "register"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;