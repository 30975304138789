
import * as React from "react";
import { ThemeProvider } from '@mui/material/styles';
import theme from '../styles/theme';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
	Grid,
    IconButton,
    Tooltip,
} from "@mui/material";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import {
    beauty,
    coin,
    dice,
    dictator,
    dictatorreverse,
    dishonestyaltruistic,
    dishonestygroup,
    dishonestyindividual,
    goldenballs,
    financialdecision,
    raven,
    taxevasion,
    ultimatumreceiver,
    ultimatumsender,
    videos,
} from "../utils/gameDescriptions";
import InfoIcon from '@mui/icons-material/Info';

const getTextFromId = (gameId, language, dataDescription, dataUser, dataResearcher) => {
  const gameDic = {
    'beauty': beauty,
    'coin': coin,
    'dice': dice,
    'dictator': dictator,
    'dictatorreverse': dictatorreverse,
    'dishonestyaltruistic': dishonestyaltruistic,
    'dishonestygroup': dishonestygroup,
    'dishonestyindividual': dishonestyindividual,
    'goldenballs': goldenballs,
    'financialdecision': financialdecision,
    'raven': raven,
    'taxevasion': taxevasion,
    'ultimatumreceiver': ultimatumreceiver,
    'ultimatumsender': ultimatumsender,
    'videos': videos,
  };
  const markdownDescription = gameDic[gameId](`${language}Description`, dataDescription);
  const markdownUser = gameDic[gameId](`${language}User`, dataUser);
  const markdownResearcher = gameDic[gameId](`${language}Researcher`, dataResearcher);

  return { markdownDescription, markdownUser, markdownResearcher };
};

// markup
const BeGameInstructions = ({ gameId, dataUser = {}, dataResearcher = {}, dataDescription = {} }) => {
    const { t, i18n } = useTranslation();
    const [informationOpen, setInformationOpen] = React.useState(false)
    const language = i18n.language;
    const { markdownDescription, markdownUser, markdownResearcher } = getTextFromId(gameId, language, dataDescription, dataUser, dataResearcher);

    return (
        <ThemeProvider theme={theme}>
        <main >
            <Grid container spacing={2} pl={'16px'} pr={'16px'}>
                <Grid item xs={11}>
                    <ReactMarkdown children={markdownDescription} remarkPlugins={[remarkGfm]} />
                </Grid>
                <Grid item xs={1} alignSelf={'center'}>
                    <Tooltip title={t("Open user information")}>
                        <IconButton
                            aria-label="Open user information"
                            size="large"
                            color="info"
                            onClick={() => {
                                setInformationOpen(true);
                            }}>
                            <InfoIcon fontSize="24" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} textAlign={'justify'}>
                    <ReactMarkdown children={markdownResearcher} remarkPlugins={[remarkGfm]} />
                </Grid>
                
            </Grid>
        </main>
        <Dialog
            open={informationOpen}
            onClose={() => {setInformationOpen(false)}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Trans>Instructions for the participant</Trans>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <ReactMarkdown children={markdownUser} remarkPlugins={[remarkGfm]} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {setInformationOpen(false)}} autofocus>
                        <Trans>Close</Trans>
                </Button>
            </DialogActions>
        </Dialog>
        </ThemeProvider>
    )
}

export default BeGameInstructions