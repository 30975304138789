import * as React from 'react';
import { Grid } from '@mui/material';
import BeGeneralPage from './be-general-page';

// styles
const verticalPadding40 = {
	paddingTop: '40px',
	paddingBottom: '40px',
};

const formContainer = {
	borderRadius: '12px',
	textAlign: 'center',
};

// markup
const GameCreationRoot = ({ children, title }) => {
	return (
		<BeGeneralPage title={title}>
			<Grid style={verticalPadding40} justifyContent={"center"} container spacing={2}>
				<Grid item style={formContainer} xs={12}>
					{children}
				</Grid>
			</Grid>
		</BeGeneralPage>
	);
};

export default GameCreationRoot;