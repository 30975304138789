import React from 'react';
import {
	Box,
	Button,
    FormControl,
    FormControlLabel,
    FormLabel,
	LinearProgress,
    MenuItem,
    Radio,
    RadioGroup
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import {
	Select,
  } from 'formik-mui';
import Layout from '../layout';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import {
	doc,
	getDoc,
} from "firebase/firestore";
import { db } from '../../../firebase-info';
import useLocalStorageState from 'use-local-storage-state'
import { navigate } from "gatsby";
import { GamesContext, AuthContext } from '../../contexts/contexts.js';
import { randomIntFromInterval } from '../../utils/utils';

const formContainer = {
	width: '100%',
	marginTop: '32px',
};
const inputWidth = {
	minWidth: '80%'
};

const GoldenBallsForm = ({handleOnChange }) => {
	const { t } = useTranslation();
    const gamesInfo = React.useContext(GamesContext);
    const userInfo = React.useContext(AuthContext);
    const [experiment, setExperiment] = useLocalStorageState("experiment");
    const [gamePosition, setGamePosition] = useLocalStorageState("gamePosition", {defaultValue: undefined});

	const handleSubmit = async (values) => {
		try {
            if (gamePosition !== undefined) {
                const game = {...experiment.games[gamePosition], params: {...values}};

                experiment.games[gamePosition] = game;
            } else {
                const gameData = gamesInfo.filter((game) => game.id === "goldenballs");
                const game = {...gameData[0], params: {...values}};

                const gamesLikeThis = experiment.games.filter((g) => (game.id === g.id))
                const maxInstance = Math.max(gamesLikeThis.map((g) => g.instance))
                if(!maxInstance){
                    game.instance = 1;
                }
                else{
                    game.instance = maxInstance+1;
                }
                game["listId"] = `${game.id}_${game.instance}`;
                experiment.games.push(game);
            }
            
            setGamePosition(undefined);
            setExperiment(experiment);

		} catch(e) {
            console.log(e);
            //we should handle the error
		}
	}
	const validate = values => {
		const errors = {};
	 
		if (!values.numberOfVideos) {
			errors.numberOfVideos = t('Required');
        }

		if (!values.videoOrder) {
			errors.videoOrder = t('Must be a number');
		}

		return errors;
	  };

    const goBack = () => {
        if(gamePosition !== undefined){
            navigate("/checkoutExperiment/");
        }
        else{
            navigate(-1);
        }
    }

	return(
        <Layout>
            <Formik
                initialValues={
                    (gamePosition !== undefined && experiment?.games[gamePosition].params) || {
                    numberOfVideos: 5,
                    videoList: [],
                    videoOrder: 'fixed',
                }}
                style={formContainer}
                onSubmit={async (values, actions) => { 
                    if(values.videoOrder === 'fixed') {
                        const videoSelection = new Set();

                        do {
                            videoSelection.add(randomIntFromInterval(1, 5));
                        } while (videoSelection.size < values.numberOfVideos)

                        values.videoList = Array.from(videoSelection);
                    }
                    await handleSubmit(values)
                    actions.setSubmitting = false;
                    goBack();
                }}
                validate={(values) => { return validate(values) }}
            >
                {({values, submitForm, resetForm, isSubmitting, setFieldValue}) => (
                    <Form onChange={handleOnChange}>
                        <Box margin={1}>
                            <Field
                                component={Select}
                                formControl={{ sx: { width: '15%' }}}
                                id="numberOfVideos"
                                name="numberOfVideos"
                                labelId="numberOfVideos"
                                defaultValue=""
                                label={<Trans>Select number of videos</Trans>}
                            >
                                {["1","2","3","4","5"].map((item) => (<MenuItem key={item} value={item}>{item}</MenuItem>))}
                            </Field>
                        </Box>
                        <Box margin={1}>
                            <FormControl component="fieldset">
                                <FormLabel id="fixed-or-random"><Trans>Select video order</Trans></FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="fixed-or-random"
                                    name={"videoOrder"}
                                    value={values.videoOrder.toString()} onChange={(event) => {
                                        setFieldValue("videoOrder", event.currentTarget.value)
                                    }}
                                >
                                    <FormControlLabel
                                        name={"videoOrder"}
                                        control={<Radio />}
                                        label={t("Fixed")}
                                        value={"fixed"}
                                    />
                                    <FormControlLabel
                                        name={"videoOrder"}
                                        control={<Radio />}
                                        label={t("Random")}
                                        value={"random"}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        {isSubmitting && <LinearProgress />}
                        <Box margin={1}>
                            <Button
                                sx={{margin: 1}}
                                variant="contained"
                                size="large"
                                color="primary"
                                disabled={isSubmitting || !userInfo?.userData.gamePermissions.goldenballs.usable}
                                onClick={submitForm}
                            >
                                {gamePosition !== undefined ? 
                                    <Trans>Modify game parameters</Trans>
                                :
                                    <Trans>Add game to experiment</Trans>
                                }
                            </Button>
                            <Button
                                sx={{margin: 1}}
                                variant="contained"
                                color="secondary"
                                size="large"
                                disabled={isSubmitting}
                                onClick={() => {
                                    resetForm();
                                }}
                            >
                                <Trans>Reset values</Trans>
                            </Button>
                            <Button
                                sx={{margin: 1}}
                                variant="contained"
                                color="error"
                                size="large"
                                disabled={isSubmitting}
                                onClick={() => {
                                    goBack();
                                }}
                            >
                                <Trans>Cancel</Trans>
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Layout>
	);
}

export default GoldenBallsForm;